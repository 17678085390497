<template>
  <div>
    <div class="plan-banner_bg"></div>
    <div class="container wrap">
      <h3>DOCTOR ON CALL</h3>
      <div class="d-flex mb-3">
        <div class="buying-icon">
          <img src="../../assets/images/buying-power.svg" />
          <br />
          <h6>Buying Power</h6>
        </div>
        <div class="desc-buying">
          Access to the benefits and large group pricing on this website is
          predicated upon joining Elevate Wellness, a national association with
          over 90,000 members.
          <!-- Learn more -->
          <router-link to="" v-b-modal.learn>
            <h5 class="learn-more-link">Learn more</h5></router-link
          >
          <b-modal id="learn" centered size="lg" v-model="show">
            <template #modal-header>
              <div class="popUp-header">
                <div>Elevate Wellness</div>
                <b-button variant="close-btn" @click="show = false">
                  <i class="fas fa-times"></i>
                </b-button>
              </div>
            </template>
            <b-container fluid>
              <div>
                <p>
                  Elevate Wellness Association ("ELEVATE") is a national
                  organization dedicated to the health and wellness of employees
                  at small and midsize companies.
                </p>
                <p>
                  ELEVATE provides health information, including fitness,
                  nutritional and lifestyle choices via its website,
                  e-newsletters and blogs, to help employees live healthier,
                  happier lives.
                </p>
                <p>
                  There are health advocates available as well to assist the
                  members through billing questions and alternative treatment
                  options that may not be available through their traditional
                  healthcare providers.
                </p>
                <p>
                  Formed prior to 1993, the association now serves over 92,000
                  members from relationships with over 5,000 organizations.
                  Through ELEVATE, members can also access valuable lifestyle,
                  supplemental and voluntary benefits that improves members'
                  lives.
                </p>
                ELEVATE uses the power of its group buying to offer benefits
                that previously were not available to members who may be working
                at smaller organizations, and at costs that are lower than those
                plans found in the retail market space. For more information
                about the Elevate Wellness Association, visit the website.
              </div>
            </b-container>
          </b-modal>
        </div>
      </div>
      <!-- Get Started modal -->
      <router-link to="" v-b-modal.start
        ><button class="get-started">Enroll Now</button></router-link
      >
      <b-modal id="start" centered size="lg" v-model="view">
        <template #modal-header>
          <div class="popUp-header">
            <div>Please provide the following information.</div>
            <b-button variant="close-btn" @click="view = false">
              <i class="fas fa-times"></i>
            </b-button>
          </div>
        </template>
        <b-container fluid>
          <div class="content">
            <form>
              <div class="mb-3">
                <label>Plan Type</label>
                <br />
                <select
                  type="text"
                  placeholder="Select Plan Type"
                  class="input-field"
                >
                  <option selected="true" hidden>Plain Type</option>
                  <option value="Member">Member</option>
                  <option value="Member + Spouse">Member + Spouse</option>
                  <option value="Member + Children">Member + Children</option>
                  <option value="Family">Family</option>
                </select>
              </div>
              <div class="mb-3">
                <label>Birth Date</label>
                <br />
                <input
                  type="date"
                  placeholder="Select Plan Type"
                  class="input-field"
                />
              </div>
              <div class="mb-3">
                <label>Zip Code</label>
                <br />
                <input
                  type="text"
                  placeholder="Enter Zip Code"
                  class="input-field"
                />
              </div>
              <button class="view_plans">View Plans</button>
            </form>
          </div>
        </b-container>
      </b-modal>
    </div>
    <div class="container plan-container mt-1">
      <h5 class="search-title">Find Participating Veterinarians</h5>
      <input
        type="text"
        placeholder="Search a Veterinarians"
        class="search-field"
      />
      <button class="btn-search">Search</button>
    </div>
  </div>
</template>
  <script>
export default {
  name: "Plan_Details",
  data() {
    return {
      show: false,
      view: false,
    };
  },
};
</script>
  
  <style>
.plan-banner_bg {
  background: url(../../assets/images/plan-banner.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
  min-height: 380px;
}
.wrap {
  margin: 4rem 1rem;
}
.wrap h3 {
  font-weight: 700;
  color: #333;
  margin-bottom: 35px;
}
.plan-container {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 2rem;
  background: #fff;
  border-radius: 6px;
  padding: 2rem;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  margin-top: -2rem;
}
.buying-icon img {
  min-width: 70px;
  margin-bottom: 0.5rem;
}
.buying-icon h6 {
  margin-left: 0.6rem;
  color: #5f5d5d;
  font-weight: 600;
  line-height: 25px;
}
.desc-buying {
    line-height: 28px;
    font-size: 16px;
    color: #333;
    font-weight: 600;
}
.learn-more-link {
  color: #2c5f97;
  font-weight: 700;
  margin-top: 0.5rem;
}
.get-started {
  border: none;
  color: #fff;
  background: #218838;
  padding: 1rem;
  border-radius: 6px;
  min-width: 250px;
}
.search-field {
  border: 0.5px solid #c7c5c5;
  border-radius: 8px;
  padding: 0.8rem 1rem;
  min-width: 450px;
}
.search-title {
  color: #5f5d5d;
}
.btn-search {
  background: #2c5f97;
  color: #fff;
  border: none;
  color: #fff;
  padding: 0.8rem 1rem;
  border-radius: 6px;
  min-width: 150px;
  margin-left: 0.5rem;
}
.input-field {
  border: 0.5px solid #c7c5c5;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  min-width: 450px;
  width: 100%;
}
.input-field:focus-visible,
.search-field:focus-visible {
  outline: none;
}
.content label {
  font-size: 15px;
  color: #4c4c4c;
  font-weight: 600;
}
.popUp-header button:hover {
  background: #eeeeee;
  color: #000;
}

.view_plans {
  border: none;
  color: #fff;
  background: #218838;
  padding: 0.8rem;
  border-radius: 6px;
  min-width: 100%;
  margin-top: 0.5rem;
}
.popUp-header {
  padding-bottom: 1rem;
}

</style>